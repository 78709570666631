<template>
  <div class="is-fullwidth date-scroll-picker">
    <div class="is-fullwidth is-relative">
      <div class="scroll-picker-fade left"></div>
      <div class="scroll-picker-fade right"></div>
      <PerfectScrollbarWrapper ref="monthsScrollContainer" class="months-scrollbar is-clickable">
        <div class="level is-mobile  months-container">
          <div v-for="month in months" :key="month.monthWord+'-'+month.year" :ref="month.monthWord+'-'+month.year"
               class="level-item">
            <div @click.stop="monthClick(month)">
              <div  class="is-flex month-item is-justify-content-center is-align-items-center">
                <span :class="{'has-text-black': month.isFocused}" class="subtitle is-size-7"
                >{{ month.monthWord }}</span>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbarWrapper>
    </div>
    <div class="is-fullwidth is-relative">
      <div class="scroll-picker-fade left"></div>
      <div class="scroll-picker-fade right"></div>
      <PerfectScrollbarWrapper ref="daysScrollContainer" class="days-scrollbar is-clickable">
        <div class="level is-mobile  days-container">
          <div v-for="day in days" :key="day.dayOfYear+'-'+day.year" :ref="day.dayOfYear+'-'+day.year"
               class="level-item">
            <div @click.stop="dayClick(day)">
              <div
                  :class="{'has-text-white has-background-primary': day.isFocused, 'has-background-light':day.hasEvents}"
                  class="day-number-item  is-flex is-justify-content-center is-align-items-center">
                <span>{{ day.dayNumber }}</span>
              </div>
              <div class="is-flex is-justify-content-center is-align-items-center">
                <span class="subtitle is-size-7">{{ day.dayWord }}</span>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbarWrapper>
    </div>


  </div>

</template>

<script>


import {
  addDays,
  addMonths,
  format,
  getDayOfYear,
  isDate,
  isSameDay,
  isSameMonth,
  lastDayOfMonth,
  subMonths
} from "date-fns";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import {mapState} from "vuex";

export default {
  name: "DateScrollPicker",
  components: {PerfectScrollbarWrapper},
  data() {
    return {
      page: 1,
      displayPage: 1,
      meta: Object,
      searchTerm: null,
      loadingSearch: false,
      loading: false,
      loaded: false,
      eventsHold: null,
      creator: null,
      name: null,
      is_internal: null,
    }
  },
  props: {
    events: {
      type: Array,
      default() {
        return []
      }
    }
  },

  methods: {
    dayClick(day) {
      this.$emit('dayClick', day)
    }, monthClick(month) {
      console.log(month)
      this.$emit('monthClick', month)
    },
    scrollToDate(date) {
      let refString = getDayOfYear(date) + '-' + date.getFullYear()
      this.$refs.daysScrollContainer.scrollHorizontalTo(this.$refs[refString][0].offsetLeft - ((this.$store.state.size.width)-this.$refs[refString][0].clientWidth)/2)
    },
    scrollToMonth(date) {
      let refString = format(date, 'MMMM') + '-' + date.getFullYear()
      this.$refs.monthsScrollContainer.scrollHorizontalTo(this.$refs[refString][0].offsetLeft - ((this.$store.state.size.width)-this.$refs[refString][0].clientWidth)/2)
    }
  },
  watch: {
    focus_date_filter(newVal) {
      if (isDate(newVal)) {
        this.$nextTick(()=>{
          this.scrollToDate(newVal)
          this.scrollToMonth(newVal)

        })
      }
    }
  },
  computed: {
    ...mapState('calendar', ['focus_date_filter']),
    days() {
      let days = []
      let start = subMonths(this.$store.state.calendar.start_date_filter, 1)
      let end = lastDayOfMonth(addMonths(this.$store.state.calendar.start_date_filter, 1))
      let date = new Date(start)
      while (!isSameDay(date, end)) {
        days.push({
          dayWord: format(date, 'eee'),
          dayNumber: date.getDate(),
          year: date.getFullYear(),
          dayOfYear: getDayOfYear(date),
          date: date,
          isFocused: isSameDay(date, this.$store.state.calendar.focus_date_filter),
          hasEvents:  this.events.findIndex(event => {
            return isSameDay(new Date(event.start_date_time),date)
          }) !==-1
        })
        date = addDays(date, 1)

      }
      return days
    },
    months() {
      let months = []
      let start = subMonths(this.$store.state.calendar.start_date_filter, 7)
      let end = addMonths(this.$store.state.calendar.start_date_filter, 6)
      let date = new Date(start)
      while (!isSameMonth(date, end)) {
        months.push({
          monthWord: format(date, 'MMMM'),
          year: date.getFullYear(),
          date: date,

          isFocused: isSameMonth(date, this.$store.state.calendar.focus_date_filter)
        })
        date = addMonths(date, 1)

      }
      return months
    }
  },
  mounted() {
    this.scrollToDate(this.focus_date_filter)
    this.scrollToMonth(this.focus_date_filter)
  }
}
</script>
