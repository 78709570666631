<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Event</p>
      </header>
      <PerfectScrollbarWrapper>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="Title">
          <b-input
              v-model="eventObject.name"
              placeholder="Event Title"
              required>
          </b-input>
        </b-field>
        <b-field v-if="eventObject.all_day===1" expanded label="Start">
          <b-datepicker
              :years-range="[-2,2]" placeholder="Start" required expanded
              v-model="start"></b-datepicker>
        </b-field>
        <b-field :type="{'is-danger':isSameDay}"
                 :message="isSameDay?'The Ends By date needs to be after the Start date':''"
                 v-if="eventObject.all_day===1" expanded label="Ends By">
          <b-datepicker
              :years-range="[-2,2]" placeholder="End" required expanded
              v-model="end"></b-datepicker>
        </b-field>
        <b-field v-if="eventObject.all_day!==1" expanded label="Start">
          <b-datetimepicker
              :timepicker="{hourFormat:'24'}"
              :years-range="[-2,2]" placeholder="Start" required expanded
              v-model="start"></b-datetimepicker>
        </b-field>
        <b-field v-if="eventObject.all_day!==1" expanded label="End">
          <b-datetimepicker
              :timepicker="{hourFormat:'24'}"

              :years-range="[-2,2]" placeholder="End" required expanded
              v-model="end"></b-datetimepicker>
        </b-field>
        <b-field label="All Day">
          <b-switch v-model="eventObject.all_day" :true-value="1" :false-value="0">
            {{ eventObject.all_day === 1 ? 'Yes' : 'No' }}
          </b-switch>

        </b-field>
        <b-field label="Event Type">
          <b-select required v-model="eventObject.event_type_id"
                    placeholder="Event Type">
            <option
                v-for="types of eventTypes"
                :key="types.id"
                :value="types.id"
            >{{ types.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="For staff only">
          <b-switch v-model="eventObject.is_internal" :true-value="true" :false-value="false">
            {{ eventObject.is_internal ? 'Yes' : 'No' }}
          </b-switch>

        </b-field>
        <b-field v-if="eventObject.is_internal" label="Department(s)">

          <b-dropdown :max-height="300" scrollable v-model="eventObject.filters.department_ids" multiple
                      aria-role="list">
            <b-button expanded type="is-primary"
                      slot="trigger" :icon-right="'chevron-down'">
              <span>Select Department(s)</span>

            </b-button>
            <b-dropdown-item :class="{'is-active':eventObject.filters.department_ids.length===0}"
                             @click.native.prevent="eventObject.filters.department_ids=[]">All
            </b-dropdown-item>
            <b-dropdown-item
                v-for="department of departments"
                :key="department.id"
                :value="department.id"
            >
              <span>{{ department.name }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field label="Campuses">
          <b-dropdown

              v-model="eventObject.campus_ids"
              scrollable
              max-height="300"
              multiple
              aria-role="list"
          >
            <b-button type="is-primary"
                      expanded
                      :icon-right="'chevron-down'"
                      slot="trigger"
            >
              <span>Select Campuses: [{{ eventObject.campus_ids.length }}]</span>

            </b-button>
            <b-dropdown-item @click.native.prevent="setAllCampuses"
                             :class="{'is-active':eventObject.campus_ids.length>=campuses.length}" :value="null">
              <span>All</span>
            </b-dropdown-item>
            <b-dropdown-item v-for="campus of campuses"
                             :key="campus.id"
                             :value="campus.id">
              {{ campus.name }}
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <b-field style="overflow: hidden" label="Description">
          <ckeditor style="overflow: hidden"
                    :editor="editor"
                    v-model="eventObject.description"
                    :config="editorConfig"
          ></ckeditor>
        </b-field>


        <MediaPanel
            v-if="loaded"
            :submit_button="false"
            :submit_flip="submitMedia"
            @submitted="submitMedia=false;$emit('close')"
            :title="'Event Media'" :model="'events'"
            :has-tag="false"
            :model_id="parseInt(eventObject.id)"
            :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
            :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
        ></MediaPanel>
      </section>
      </PerfectScrollbarWrapper>
      <footer v-if="inModal" class="modal-card-foot">
        <b-button :loading="loading" type="is-primary" native-type="submit"
                  :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">{{ (edit || loaded) ? 'Save' : 'Submit' }}
        </b-button>
      </footer>
      <b-field grouped class="mt-4" v-else>
        <div class="control">
          <b-button :loading="loading" native-type="submit"
                    tag="input"
                    type="submit"> {{ (edit || loaded) ? 'Save' : 'Submit' }}
          </b-button>
        </div>
        <div v-if="edit" class="control">
          <b-button :loading="loadingDelete" @click.prevent="startDelete" type="is-danger">
            Delete
          </b-button>
        </div>
      </b-field>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import MediaPanel from "@/components/media/MediaPanel";
import Campus from "@/models/Campus";
import {
  addHours,
  // forma,
  isSameDay, set
} from "date-fns";
import EventType from "@/models/EventType";
import CalendarEvent from "@/models/CalendarEvent";
import Department from "@/models/Department";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "EventForm",
  components: {MediaPanel, PerfectScrollbarWrapper},
  data() {
    return {
      loading: false,
      loadingDelete: false,
      loaded: false,
      showMedia: false,
      submitMedia: false,
      submitAttempted: false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The description of the event."},
      },
      eventObject: {
        name: '',
        description: '',
        start_date_time: new Date(),
        end_date_time: new Date(),
        campus_ids: [this.$store.state.campus.selected_campus_id],
        event_type_id: null,
        is_internal: false,
        all_day: 0, filters: {
          department_ids: []
        }
      }
    }
  },
  methods: {
    setAllCampuses() {
      this.eventObject.campus_ids = this.campuses.map(campus => {
        return campus.id
      })
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting event',
        confirmText: 'Delete Event',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this event?',
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.loading = true
      CalendarEvent.Delete(this.eventObject.id).then(() => {
        this.$buefy.snackbar.open(`Event deleted!`);
        this.$emit("close");
        this.$emit("deleted");
        this.$emit('update-events')
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      });

    },
    submit() {
      this.submitAttempted = true
      let submitObject = this.eventObject
      if (submitObject.is_internal !== true) {
        submitObject.filters.department_ids = []
      }
      if (submitObject.all_day === 1) {
        submitObject.start_date_time = set(new Date(submitObject.start_date_time), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0
        })
        submitObject.end_date_time = set(new Date(submitObject.end_date_time), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0
        })

      }
      if (submitObject.all_day === 1 && this.isSameDay) {
        return
      }
      if (this.edit || this.loaded) {
        this.loading = true
        CalendarEvent.Update(submitObject, true).then(() => {
          this.$buefy.snackbar.open(`Event updated!`)
          this.submitMedia = true
          this.$emit('update-events')
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        this.loading = true
        CalendarEvent.Store(submitObject).then(({entities: {calendar_events}}) => {
          this.$buefy.snackbar.open(`Event created!`)
          this.$emit('update-events')
          this.eventObject.id = calendar_events[0].id
          this.showMedia = true
          this.loaded = true
          this.loading = false
          this.submitMedia = true
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    }
  },

  computed: {
    isSameDay() {
      return isSameDay(new Date(this.start), new Date(this.end))
    },
    departments() {
      return Department.query().orderBy('name').get()
    },
    eventTypes() {
      return EventType.query().get()
    },
    start: {
      set(newVal) {
        this.eventObject.start_date_time = newVal
      },
      get() {
        if (this.eventObject.start_date_time == null) {
          return null;
        }
        return new Date(this.eventObject.start_date_time);
      },
    }, end: {
      set(newVal) {
        this.eventObject.end_date_time = newVal
      },
      get() {
        if (this.eventObject.end_date_time == null) {
          return null;
        }
        return new Date(this.eventObject.end_date_time);
      },
    },
    campuses() {
      return Campus.query().get()
    },
  },
  mounted() {
    this.loading = true
    Promise.all([Campus.FetchAll({page: 1, limit: 99}), Department.FetchAll()]).then(() => {
      if (this.edit) {
        this.eventObject = JSON.parse(JSON.stringify(this.calendarEvent))
        this.loaded = true
      } else {
        this.start = new Date()
        this.end = addHours(new Date(), 1)
      }

      if (this.inModal) {
        this.makeModalLarge()

      }
      this.loading = false

    }).catch(err => {
      this.handleError(err)
      this.loading = false
    })
  },
  props: {
    calendarEvent: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

