<template>
  <div class="listitem event-item">
    <article class="media mb-1">
      <!--      <figure class="media-left">-->
      <!--        <p class="has-text-centered">-->
      <!--          <b-tooltip append-to-body :label="calendarEvent.event_type.name" position="is-top">-->
      <!--            <b-icon :class="['event-' + calendarEvent.event_type_id]" class="icon rounded is-large box is-64x64"-->
      <!--                    :icon="$tc(`icons.events`)"></b-icon>-->
      <!--          </b-tooltip>-->
      <!--        </p>-->
      <!--        Future mark read function perhaps -->
      <!--        <p class="has-text-centered mt-2">-->
      <!--          <b-field>-->
      <!--            <b-tooltip-->
      <!--                :label="activity.is_complete!=1?'Mark as complete':'Mark as incomplete'"-->
      <!--                position="is-top"-->
      <!--            >-->
      <!--              <b-button-->
      <!--                  :loading="loadingActivity"-->
      <!--                  :type="{'is-success':activity.is_complete==1}"-->
      <!--                  class="is-rounded"-->
      <!--                  @click.prevent="completeActivity()"-->
      <!--              >-->
      <!--                <b-icon :icon="$tc('icons.tick')"></b-icon>-->
      <!--              </b-button>-->
      <!--            </b-tooltip>-->
      <!--          </b-field>-->
      <!--        </p>-->
      <!--      </figure>-->
      <div :class="{'py-1':!inModal,'box large-box py-6':inModal}"
           class=" media-content is-relative has-pointer py-1 pb-4"
           @click="handleClick()">
        <div class="columns is-fullwidth is-vcentered is-mobile">
          <div class="column is-2 pr-0">
            <p v-if="calendarEvent.all_day!==1" :class="{'mb-0':!inModal,'has-text-centered':inModal}" class="is-7 has-text-black">
              <!--            Created by {{ announcement.creator.first_name }} {{ announcement.creator.last_name }}.-->
              {{
                formatDate(calendarEvent.start_date_time, false)

              }}
              <!--              to-->
              <!--              {{ formatDate(calendarEvent.end_date_time) }}-->
            </p>
            <p v-else :class="{'mb-0':!inModal,'has-text-centered':inModal}" class="is-7 has-text-black">
              <!--            Created by {{ announcement.creator.first_name }} {{ announcement.creator.last_name }}.-->
              All day
              <!--              {{-->
              <!--                formatDate(calendarEvent.start_date_time, true, calendarEvent.end_date_time)-->

              <!--              }}-->


            </p>

          </div>
          <div class="column is-1">
            <div :class="['event-' + calendarEvent.event_type_id]" class="event-divider"></div>
          </div>
          <div class="content column is-9">

            <div :class="{'mb-0':!inModal}" class="level is-mobile has-dropdown">
              <div class="level-left">
                <div class="level-item">

                </div>
              </div>
              <div v-if="showDropdown&&(permissions.can_delete||permissions.can_edit)" class="level-right">
                <div class="level-item">
                  <b-dropdown v-if="permissions.can_delete||permissions.can_edit" :mobile-modal="false"
                              aria-role="list"
                              class="listitem-dropdown"
                              position="is-bottom-left"
                              @click.native.stop>
                    <b-button slot="trigger" :icon-right="$tc('icons.more')" class=" is-rounded" type="is-ghost">
                    </b-button>

                    <b-dropdown-item v-if="permissions.can_edit" aria-role="listitem" @click="startEdit">Edit
                    </b-dropdown-item>
                    <b-dropdown-item v-if="permissions.can_delete" aria-role="listitem"
                                     @click="startDelete(calendarEvent)">
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>

                </div>
              </div>
            </div>
            <div class="subtitle is-size-7 mb-1">
              {{ calendarEvent.event_type.name }}
              <span v-if="calendarEvent.all_day===1"> - {{
                  formatDate(calendarEvent.start_date_time, true, calendarEvent.end_date_time)

                }}</span>
            </div>
            <h3 :class="{'mb-0':!inModal}" class="is-capitalized is-size-5 mt-0" v-html="calendarEvent.title"></h3>


            <div v-if="inModal" class="content" v-html="calendarEvent.description"></div>
            <b-field v-if="media.length>0 && showMedia">
              <b-taglist><a v-for="file in media" :key="file.id" :href="file.temporary_url" class="tag"
                            download target="_blank">{{ file.caption }}</a></b-taglist>

            </b-field>
          </div>
        </div>
      </div>
    </article>

  </div>
</template>

<script>
import clip from "text-clipper";
import {format, isSameDay, subDays} from "date-fns";
import CalendarEvent from "@/models/CalendarEvent";
import EventForm from "@/components/calendar/EventForm";
import Media from "@/models/Media";


export default {
  name: "EventListItem",
  props: {
    showDropdown: {
      type: Boolean,
      default() {
        return true
      }
    },
    cropLength: {
      type: Number,
      default() {
        return 50
      }
    },
    showMedia: {
      type: Boolean,
      default() {
        return true
      }
    }, inModal: {
      type: Boolean,
      default() {
        return false
      }
    },
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_edit: false,
      }),
    },
    calendarEvent: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {
      loading: false,
      expanded: false

    };
  },

  computed: {
    media() {
      return Media.query()
          .where('mediable_type', 'events')
          .where('mediable_id', this.calendarEvent.id).get()
    }
  },
  mounted(){
    if (this.inModal) {
      this.makeModalLarge()
    }
  },
  methods: {
    formatDate(date, isFullDay = false, endDate) {
      if (date === null) {
        return null
      }
      if (isFullDay) {
        if (isSameDay(new Date(date), subDays(new Date(endDate), 1))) {
          return format(new Date(date), `do MMMM`)
        }
        return `${format(new Date(date), `do MMMM`)} to ${format(subDays(new Date(endDate), 1), `do MMMM`)}`
      }
      return format(new Date(date), `HH:mm`)
    },
    handleClick() {
      if (this.inModal) {
        return null
      }
      this.$emit('clicked')
    },
    clipContent(text) {
      if (text) {
        return clip(text, this.cropLength, {
          html: true,
        });
      }
      return ""
    },
    startEdit() {
      if (this.permissions.can_edit) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            calendarEvent: this.calendarEvent,
            edit: true,
            inModal: true,
          },
          component: EventForm,
          fullScreen: false,
          trapFocus: true,
          canCancel: ['outside', 'x'],
          events: {
            "update-events": () => {
              this.$emit('close')
              this.$emit('update-events')
            }
          },
        });
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
    deleteEvent(event) {
      this.$store.dispatch("loader/show");
      CalendarEvent.Delete(event.id).then(
          () => {
            this.$emit('update-events')
            this.$buefy.snackbar.open('Event deleted!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            this.handleError(err)
          }
      )

    },
    startDelete(event) {
      if (this.permissions.can_delete) {
        this.$buefy.dialog.confirm({
          title: "Deleting event",
          message:
              "Are you sure you want to <b>delete</b> this event? This action cannot be undone.",
          confirmText: "Delete Event",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteEvent(event),
        });
      } else {
        this.$store.dispatch("toast/createToast");

      }
    },
  },
};
</script>
