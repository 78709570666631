<template>
  <div ref="event_container" :class="{'box':$store.getters['size/viewType']!=='mobile'}"
       class="is-relative event-panel tile-panel">
    <div :class="{'mb-5':$store.getters['size/viewType']!=='mobile','pl-5':$store.getters['size/viewType'] !=='tablet'}" class="header-container pt-4  pr-2 ">
      <header>
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item is-hidden-mobile">
              <h3 class="title is-capitalized is-size-4-touch">
                Calendar Events
              </h3></div>
          </div>
          <div class="level-right">
            <div class="level-item is-hidden-tablet">
              <b-field>
                <b-dropdown :close-on-click="false" :mobile-modal="false" :position="'is-bottom-left'" aria-role="list">

                  <template #trigger>
                    <b-button
                        :class="{'has-text-primary':$store.state.calendar.campus_filter!==null||$store.state.calendar.eventTypeFilter!=='All'}"
                        :icon-right="$tc('icons.filters')"
                        class="has-text-not-underlined has-text-grey"
                        outlined
                        type="is-ghost"
                    ><span class="subtitle is-size-6 has-text-not-underlined">Filter</span></b-button>
                  </template>


                  <template>

                    <b-dropdown-item :focusable="false" custom>

                      <p class="title is-size-6">
                        Campus
                      </p>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem"
                                     custom
                    >
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item"><h3>All Campuses</h3>
                          </div>
                        </div>
                        <div class="level-right">
                          <div class="level-item">
                            <b-radio v-model="$store.state.calendar.campus_filter" :native-value="null"
                                     name="event_calendar_campus_id"></b-radio>
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item v-for="campus in campuses" :key="campus.id"
                                     aria-role="listitem"
                                     custom>
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item"><h3>{{ campus.name }}</h3>
                          </div>
                        </div>
                        <div class="level-right">
                          <div class="level-item">
                            <b-radio v-model="$store.state.calendar.campus_filter" :native-value="campus.id"
                                     name="event_calendar_campus_id"></b-radio>
                          </div>
                        </div>
                      </div>

                    </b-dropdown-item>
                  </template>
                  <template>
                    <b-dropdown-item :focusable="false" custom>

                      <p class="title is-size-6">
                        Event Type
                      </p>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem"
                                     custom
                    >
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item"><h3>All Events</h3>
                          </div>
                        </div>
                        <div class="level-right">
                          <div class="level-item">
                            <b-radio v-model="$store.state.calendar.eventTypeFilter" :native-value="'All'"
                                     name="event_calendar_event_type"></b-radio>
                          </div>
                        </div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item v-for="type of eventTypes" :key="type.id + 'event_type'" :value="type.id"
                                     aria-role="listitem"
                                     custom>
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <b-icon :class="['event-' + type.id]" :icon="$tc('icons.circle')"
                                    class="media-left event-type-icon"
                                    pack="filled"></b-icon>
                            <h3>{{ type.name }}</h3>
                          </div>
                        </div>
                        <div class="level-right">
                          <div class="level-item">
                            <b-radio v-model="$store.state.calendar.eventTypeFilter" :native-value="type.id"
                                     name="event_calendar_event_type"></b-radio>
                          </div>
                        </div>
                      </div>

                    </b-dropdown-item>
                  </template>
                  <b-dropdown-item :focusable="false" custom>

                    <b-button :disabled="!$store.getters['calendar/allowTodayClick']" expanded size="is-small"
                              @click="$store.dispatch('calendar/set_focus_date', new Date())
">
                      Today
                    </b-button>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>

            </div>

          </div>
        </div>
      </header>
    </div>

    <transition mode="in-out" name="fade">
      <PerfectScrollbarWrapper class="items-container">

        <div class="card-content py-0  pb-6">
          <div>
            <div v-if="displayEventsTotal===0">
              <p>No events</p>
            </div>
            <div v-for="eventGroup in dateGroupedEvents" :key="eventGroup.date" class="">
              <p class="subtitle is-size-6 is-size-7-mobile  mb-2">{{ eventGroup.date }}</p>

              <div
                  v-for="calendarEvent in eventGroup.events"
                  :key="calendarEvent.id"
              >
                <EventListItem
                    :calendar-event="calendarEvent"
                    :crop-length="cropLength"
                    :permissions="{
                                         can_delete: canDelete,
                                         can_edit: canEdit
                                       }"
                    :show-dropdown="inPage"
                    :show-media="false"
                    @clicked="openEventModal(calendarEvent)"

                ></EventListItem>
              </div>
            </div>


            <b-loading :active.sync="loading" :can-cancel="false"
                       :is-full-page="false"></b-loading>
          </div>
        </div>
      </PerfectScrollbarWrapper>
    </transition>
    <b-pagination
        v-if="usePagination"
        :current="displayPage"
        :loading="loading"
        :per-page="displayLimit"
        :range-after="2"
        :range-before="2"
        :total="displayEventsTotal"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        class="margin-top has-background-white mb-0 px-2 panel-pagination"
        v-on:change="setPage"
    ></b-pagination>
    <!--    <footer v-if="!inPage" class="panel-footer is-clickable pb-2" @click.prevent="$emit('toggleExpand')">-->
    <!--      <hr class="mb-3">-->
    <!--      <p class="has-text-centered">Show {{-->
    <!--          $store.state.calendar.expandedPanels.includes('events') ? 'less' : 'more'-->
    <!--        }}</p>-->
    <!--    </footer>-->
  </div>


</template>

<script>

import CalendarEvent from "@/models/CalendarEvent";
import {format, isAfter, sub} from "date-fns";
import EventListItem from "@/components/calendar/EventListItem";
import EventType from "@/models/EventType";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
import Campus from "@/models/Campus";


export default {
  name: "EventsPanel",
  components: {
    EventListItem,
    PerfectScrollbarWrapper

  },
  data() {
    return {
      displayPage: 1,
      meta: Object,
      loading: false,
      loaded: false,
      announcementsHold: null,
      creator: null

    }
  },
  props: {
    isExpanded: {
      type: Boolean, default() {
        return false
      }
    },
    limit: {
      type: Number,
      default() {
        return 5
      }
    },
    cropLength: {
      type: Number,
      default() {
        return 50
      }
    },
    usePagination: {
      type: Boolean, default() {
        return this.inPage
      }
    },
    inPage: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    },
    canAdministrate: {
      type: Boolean, default() {
        return false
      }
    },

  },

  methods: {
    setPage(page) {
      this.displayPage = page
    },
    openEventModal(event) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          permissions: {
            can_delete: this.canDelete,
            can_edit: this.canEdit
          },
          inModal: true,
          calendarEvent: event
        },
        component: EventListItem,
        fullScreen: false,
        trapFocus: true,

      })
    },


  },

  computed: {
    campuses() {
      return Campus.query().get()
    },
    eventTypes() {
      return EventType.query().get()
    },

    displayLimit() {
      if (!this.usePagination) {
        return 999
      }
      if (!this.inModal) {
        return 8
      }
      let value = 5
      if (this.$store.state.size.height < 500) {
        value = 5
      } else {
        value = Math.floor(this.$store.state.size.height / 125)
      }
      // switch (this.$store.getters['size/viewType']) {
      //   case "mobile":
      //     value = 5;
      //     break;
      //   case "tablet":
      //     value = 5;
      //     break;
      //   case "desktop":
      //     value = 6;
      //     break;
      //   case "widescreen":
      //     value = 9;
      //     break;
      //   case "fullhd":
      //     value = 11;
      //     break;
      //   default:
      //     value = 5;
      //     break;
      // }
      return value
    },
    dateGroupedEvents() {
      let dates = []
      this.displayEvents.map(event => {
        let index = dates.findIndex(eventGroup => {
          return eventGroup.date === (format(new Date(event.start_date_time), 'd MMMM'))
        })
        if (index !== -1) {
          dates[index].events.push(event)
        } else {
          dates.push({
            date: format(new Date(event.start_date_time), 'd MMMM'),
            events: [event],
          })
        }

      })
      return dates
    },
    displayEvents() {
      return CalendarEvent.query().where(event => {
        return isAfter(new Date(event.start_date_time), sub(new Date(this.$store.state.calendar.focus_date_filter), {seconds: 1}))
      }).orderBy('start_date_time').with('event_type').offset((this.displayPage - 1) * this.displayLimit)
          .limit(this.displayLimit).get()
    },

    displayEventsTotal() {
      return CalendarEvent.query().where(event => {
        return isAfter(new Date(event.start_date_time), sub(new Date(this.$store.state.calendar.focus_date_filter), {seconds: 1}))
      }).orderBy('start_date_time').count()
    },

  }
}
</script>
