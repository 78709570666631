<template>
  <div :class="{'p-2':inModal, 'has-background-white':isMobile,'half-box':isMobile&&isMobileExpanded}" class="calendar-panel">
    <div v-if="showHeader" class="has-background-white header-container pt-4 pl-5 mb-5 pb-1">
      <header class="has-background-white ">
        <div v-if="isMobile" class="level is-mobile is-clickable" @click.prevent="$emit('toggleExpand')">

          <div class="level-left">
            <div class="level-item"><h3 class="title is-capitalized  ">
              Calendar
            </h3></div>
          </div>
          <div class="level-right">
            <div class="level-item is-clickable" >
              <a
                  aria-label="more options"
                  class="card-header-icon has-text-grey"
                  href="#"
              >
                <b-icon :icon="isMobileExpanded?$tc('icons.minimize'):$tc('icons.maximize')"/>
              </a></div>
          </div>

        </div>
        <template v-else>
          <div class="level is-hidden-tablet-only">

            <div class="level-left">

              <div class="level-item  is-clickable is-relative" @click="$refs.monthPicker.toggle()">
                <h3 class="title is-capitalized">
                  {{ currentMonth }}
                </h3>
                <b-datepicker
                    ref="monthPicker"
                    v-model="focus_date"
                    :date-formatter="date=>formatMonth(date)"
                    :icon="$tc('icons.calendar')"
                    :years-range="[-2,2]"
                    append-to-body
                    class="invisible-datepicker"
                    placeholder="Click to select..."
                    type="month"
                ></b-datepicker>
              </div>
              <div class="level-item">
                <b-field>

                  <p class="control">
                    <b-button
                        :icon-left="$tc('icons.chevron-left')"
                        size="is-medium"
                        @click="subMonth()"
                    ></b-button>
                  </p>

                  <p class="control">
                    <b-button
                        :icon-left="$tc('icons.chevron-right')" size="is-medium" @click="addMonth()"></b-button>
                  </p>
                  <p class="control">
                    <b-button :disabled="!$store.getters['calendar/allowTodayClick']" size="is-medium"
                              @click="focus_date=new Date()">
                      Today
                    </b-button>
                  </p>
                </b-field>
              </div>
              <div class="level-item">
                <b-field v-if="inModal">
                  <b-select v-model="is_internal">
                    <option :value="null">Show all</option>
                    <option :value="true">Show internal</option>
                    <option :value="false">Show external</option>
                  </b-select>
                  <b-input
                      v-model="searchTerm"
                      v-debounce:300ms="getFilteredEvents"
                      :icon-right="$tc('icons.close-circle')"
                      :icon-right-clickable="true"
                      placeholder="Event title"
                      v-on:icon-right-click="name = null;searchTerm=null"
                  ></b-input>
                </b-field>

              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <b-field>
                  <b-button v-if="canCreate && inModal" :icon-right="$tc('icons.create')" class="has-text-grey"
                            size="is-medium"
                            type="is-light"

                            @click="addEvent()">
                    Add Event
                  </b-button>
                  <b-button v-if="canCreate && !inModal" :icon-right="$tc('icons.create')" class="has-text-grey"
                            size="is-medium"
                            type="is-light"
                            @click="addEvent()">

                  </b-button>
                </b-field>
              </div>
              <div class="level-item">
                <b-field>
                  <b-dropdown :close-on-click="false" :position="'is-bottom-left'" aria-role="list">

                    <template #trigger>
                      <b-button
                          :class="{'has-text-primary':$store.state.calendar.campus_filter!==null||$store.state.calendar.eventTypeFilter!=='All'}"
                          :icon-right="$tc('icons.filters')"
                          class="has-text-not-underlined has-text-grey"
                          outlined
                          type="is-ghost"
                      ><span class="subtitle is-size-6 has-text-not-underlined">Filter</span></b-button>
                    </template>


                    <template>
                      <b-dropdown-item :focusable="false" custom>

                        <p class="title is-size-6">
                          Campus
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item aria-role="listitem"
                                       custom
                      >
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item"><h3>All Campuses</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.campus_filter" :native-value="null"
                                       name="calendar_campus_id"></b-radio>
                            </div>
                          </div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item v-for="campus in campuses" :key="campus.id"
                                       aria-role="listitem"
                                       custom>
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item"><h3>{{ campus.name }}</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.campus_filter" :native-value="campus.id"
                                       name="calendar_campus_id"></b-radio>
                            </div>
                          </div>
                        </div>

                      </b-dropdown-item>
                    </template>
                    <template>
                      <b-dropdown-item :focusable="false" custom>

                        <p class="title is-size-6">
                          Event Type
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item aria-role="listitem"
                                       custom
                      >
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item"><h3>All Events</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.eventTypeFilter" :native-value="'All'"
                                       name="calendar_event_type"></b-radio>
                            </div>
                          </div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item v-for="type of eventTypes" :key="type.id + 'event_type'" :value="type.id"
                                       aria-role="listitem"
                                       custom>
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item">
                              <b-icon :class="['event-' + type.id]" :icon="$tc('icons.circle')"
                                      class="media-left event-type-icon"
                                      pack="filled"></b-icon>
                              <h3>{{ type.name }}</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.eventTypeFilter" :native-value="type.id"
                                       name="calendar_event_type"></b-radio>
                            </div>
                          </div>
                        </div>

                      </b-dropdown-item>
                    </template>

                  </b-dropdown>
                </b-field>

              </div>
              <div class="level-item is-clickable" @click.prevent="$emit('toggleExpand')">
                <a
                    aria-label="more options"
                    class="card-header-icon has-text-primary"
                    href="#"
                >
                  <b-icon :icon="$tc('icons.maximize')"/>
                </a></div>
            </div>
          </div>
          <div class="level is-hidden-desktop ">

            <div class="level-left">

              <div class="level-item  is-clickable is-relative" @click="$refs.monthPicker.toggle()">
                <h3 class="title is-capitalized is-size-4-touch">
                  {{ currentMonth }}
                </h3>
                <b-datepicker
                    ref="monthPicker"
                    v-model="focus_date"
                    :date-formatter="date=>formatMonth(date)"
                    :icon="$tc('icons.calendar')"
                    :years-range="[-2,2]"
                    append-to-body
                    class="invisible-datepicker"
                    placeholder="Click to select..."
                    type="month"
                ></b-datepicker>
              </div>
              <div class="level-item">
                <b-field>

                  <p class="control">
                    <b-button
                        :icon-left="$tc('icons.chevron-left')"
                        size="is-small"
                        @click="subMonth()"
                    ></b-button>
                  </p>

                  <p class="control">
                    <b-button
                        :icon-left="$tc('icons.chevron-right')" size="is-small" @click="addMonth()"></b-button>
                  </p>
                  <p class="control">
                    <b-button :disabled="!$store.getters['calendar/allowTodayClick']" size="is-small"
                              @click="focus_date=new Date()">
                      Today
                    </b-button>
                  </p>
                </b-field>
              </div>
              <div class="level-item">
                <b-field v-if="inModal">
                  <b-select v-model="is_internal">
                    <option :value="null">Show all</option>
                    <option :value="true">Show internal</option>
                    <option :value="false">Show external</option>
                  </b-select>
                  <b-input
                      v-model="searchTerm"
                      v-debounce:300ms="getFilteredEvents"
                      :icon-right="$tc('icons.close-circle')"
                      :icon-right-clickable="true"
                      placeholder="Event title"
                      v-on:icon-right-click="name = null;searchTerm=null"
                  ></b-input>
                </b-field>

              </div>
            </div>
            <div class="level-right">
              <div class="level-item mr-0">
                <b-field>
                  <b-button v-if="canCreate && inModal" :icon-right="$tc('icons.create')" class="has-text-grey"
                            size="is-small"
                            type="is-light"

                            @click="addEvent()">
                    Add Event
                  </b-button>
                  <b-button v-if="canCreate && !inModal" :icon-right="$tc('icons.create')" class="has-text-grey"
                            size="is-small"
                            type="is-light"
                            @click="addEvent()">

                  </b-button>
                </b-field>
              </div>
              <div class="level-item mr-0">
                <b-field>
                  <b-dropdown :close-on-click="false" :position="'is-bottom-left'" aria-role="list">

                    <template #trigger>
                      <b-button
                          :class="{'has-text-primary':$store.state.calendar.campus_filter!==null||$store.state.calendar.eventTypeFilter!=='All'}"
                          :icon-right="$tc('icons.filters')"
                          class="has-text-not-underlined has-text-grey"
                          outlined
                          type="is-ghost"
                      ><span class="subtitle is-size-6 has-text-not-underlined">Filter</span></b-button>
                    </template>


                    <template>
                      <b-dropdown-item :focusable="false" custom>

                        <p class="title is-size-6">
                          Campus
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item aria-role="listitem"
                                       custom
                      >
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item"><h3>All Campuses</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.campus_filter" :native-value="null"
                                       name="calendar_campus_id"></b-radio>
                            </div>
                          </div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item v-for="campus in campuses" :key="campus.id"
                                       aria-role="listitem"
                                       custom>
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item"><h3>{{ campus.name }}</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.campus_filter" :native-value="campus.id"
                                       name="calendar_campus_id"></b-radio>
                            </div>
                          </div>
                        </div>

                      </b-dropdown-item>
                    </template>
                    <template>
                      <b-dropdown-item :focusable="false" custom>

                        <p class="title is-size-6">
                          Event Type
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item aria-role="listitem"
                                       custom
                      >
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item"><h3>All Events</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.eventTypeFilter" :native-value="'All'"
                                       name="calendar_event_type"></b-radio>
                            </div>
                          </div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item v-for="type of eventTypes" :key="type.id + 'event_type'" :value="type.id"
                                       aria-role="listitem"
                                       custom>
                        <div class="level">
                          <div class="level-left">
                            <div class="level-item">
                              <b-icon :class="['event-' + type.id]" :icon="$tc('icons.circle')"
                                      class="media-left event-type-icon"
                                      pack="filled"></b-icon>
                              <h3>{{ type.name }}</h3>
                            </div>
                          </div>
                          <div class="level-right">
                            <div class="level-item">
                              <b-radio v-model="$store.state.calendar.eventTypeFilter" :native-value="type.id"
                                       name="calendar_event_type"></b-radio>
                            </div>
                          </div>
                        </div>

                      </b-dropdown-item>
                    </template>

                  </b-dropdown>
                </b-field>

              </div>
              <div class="level-item is-clickable" @click.prevent="$emit('toggleExpand')">
                <a
                    aria-label="more options"
                    class="card-header-icon has-text-primary"
                    href="#"
                >
                  <b-icon :icon="$tc('icons.maximize')"/>
                </a></div>
            </div>
          </div>
        </template>      </header>
    </div>
    <div v-if="isMobile&&isMobileExpanded">
      <DateScrollPicker :events="events" @dayClick="event=>handleDateClick({start:event.date})" @monthClick="event=>focus_date=event.date"/>
    </div>
    <div>
      <b-field group-multiline grouped>

        <!--        <b-field v-if="dateRangePicker" expanded>-->
        <!--          <b-datetimepicker v-model="$store.state.calendar.start_date_filter" :timepicker="{hourFormat:'24'}"-->
        <!--                            :mobile-native="false" append-to-body-->
        <!--                            expanded-->
        <!--                            :icon="$tc('icons.calendar')">-->

        <!--          </b-datetimepicker>-->

        <!--          <b-datetimepicker v-model="$store.state.calendar.end_date_filter" :timepicker="{hourFormat:'24'}"-->
        <!--                            :mobile-native="false" append-to-body-->
        <!--                            expanded-->
        <!--                            :icon="$tc('icons.calendar')">-->

        <!--          </b-datetimepicker>-->
        <!--        </b-field>-->
        <!--        </b-field>-->
        <!--        <b-field>-->


        <b-field>
          <!--          <b-select v-model="$store.state.calendar.eventTypeFilter">-->
          <!--            <option value="All">All events</option>-->
          <!--            <option-->
          <!--                v-for="type of eventTypes"-->
          <!--                :key="type.id"-->
          <!--                :value="type.id"-->
          <!--            >-->
          <!--              {{ type.name }}-->
          <!--            </option>-->
          <!--          </b-select>-->

          <!--          <b-datepicker :size="'is-small'" v-model="$store.state.announcements.date">-->
          <!--          </b-datepicker>-->
        </b-field>


      </b-field>
    </div>

    <div v-show="!isMobile" class="columns is-fullwidth is-multiline mt-0">
      <div :class="{'is-8-widescreen is-12-until-widescreen':inModal,'is-12':!inModal}" class="column">
        <FullCalendar ref="calendar" :options="calendarOptions" class="calendar"/>
      </div>
      <div v-if="showEventsList" :class="{'is-4-widescreen is-12-until-widescreen':inModal,'is-12':!inModal}"
           class="column">
        <div v-if="displayEventsTotal===0">
          <p>No events</p>
        </div>
        <div v-for="eventGroup in dateGroupedEvents" :key="eventGroup.date" class="">
          <p class="subtitle is-size-6  mb-2">{{ eventGroup.date }}</p>

          <div
              v-for="calendarEvent in eventGroup.events"
              :key="calendarEvent.id"
          >
            <EventListItem
                :calendar-event="calendarEvent"
                :crop-length="cropLength"
                :permissions="{
                                         can_delete: canDelete,
                                         can_edit: canEdit
                                       }"
                :show-dropdown="true"
                @clicked="openEventModal(calendarEvent)"
                @update-events="calendarOptions.events = events"


            ></EventListItem>
          </div>
        </div>
        <!--        <div-->
        <!--            v-for="calendarEvent in displayEvents"-->
        <!--            :key="calendarEvent.id"-->
        <!--        >-->
        <!--          <EventListItem-->
        <!--              :calendar-event="calendarEvent"-->
        <!--              :crop-length="cropLength"-->
        <!--              :permissions="{-->
        <!--                                   can_delete: canDelete,-->
        <!--                                   can_edit: canEdit-->
        <!--                                 }"-->
        <!--              @clicked="openEventModal(calendarEvent)"-->
        <!--              @update-events="calendarOptions.events = events"-->

        <!--          ></EventListItem>-->
        <!--        </div>-->
        <b-pagination
            :current="displayPage"
            :loading="loading"
            :per-page="displayLimit"
            :range-after="2"
            :range-before="2"
            :total="displayEventsTotal"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            class="mt-2"
            v-on:change="setDisplayPage"
        ></b-pagination>
      </div>
    </div>
    <b-loading :active.sync="loading" :can-cancel="false"
               :is-full-page="false"></b-loading>
  </div>

</template>

<script>

import CalendarEvent from "@/models/CalendarEvent";
import EventType from "@/models/EventType";
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {add, format, isAfter, isSameMonth, sub} from "date-fns";
import EventListItem from "@/components/calendar/EventListItem";
import EventForm from "@/components/calendar/EventForm";
import {mapState} from "vuex";
import Campus from "@/models/Campus";
import DateScrollPicker from "@/components/calendar/DateScrollPicker";

export default {
  name: "CalendarPanel",
  components: {
    FullCalendar,
    EventListItem,
    DateScrollPicker
  },
  data() {
    return {
      page: 1,
      displayPage: 1,
      meta: Object,
      searchTerm: null,
      loadingSearch: false,
      loading: false,
      loaded: false,
      eventsHold: null,
      creator: null,
      name: null,
      is_internal: null,
    }
  },
  props: {

    limit: {
      type: Number,
      default() {
        return 5
      }
    },
    dateRangePicker: {
      type: Boolean,
      default() {
        return false
      }
    },
    cropLength: {
      type: Number,
      default() {
        return 50
      }
    },
    isMobile: {
      type: Boolean, default() {
        return false
      }
    }, isMobileExpanded: {
      type: Boolean, default() {
        return false
      }
    }, inModal: {
      type: Boolean, default() {
        return false
      }
    }, showEventsList: {
      type: Boolean, default() {
        return false
      }
    }, showHeader: {
      type: Boolean, default() {
        return true
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    },
    canAdministrate: {
      type: Boolean, default() {
        return false
      }
    },

  },
  watch: {
    focus_date_filter(newVal) {
      let calendarApi = this.$refs.calendar.getApi()
      calendarApi.gotoDate(newVal)
    },
    filters() {
      if (this.inModal === this.$store.state.calendar.modalOpen) {
        this.setPage(this.page)
      }
    }
  },
  methods: {
    getFilteredEvents(text) {
      this.name = text;
    },
    handleEventClick($event) {
      this.openEventModal(CalendarEvent.query().where('id', parseInt($event.event.id)).with('campus').with('event_type').first())
    },
    setDisplayPage(page) {
      this.displayPage = page
    },
    handleDateClick($event) {
      if (!isSameMonth(new Date($event.start), new Date(this.$store.state.calendar.focus_date_filter))) {
        this.$store.dispatch('calendar/set_focus_date', new Date($event.start))

      } else {
        this.$store.state.calendar.focus_date_filter = new Date($event.start)

      }

    },
    addMonth() {
      this.focus_date = add(this.focus_date, {months: 1})
    }, subMonth() {
      this.focus_date = sub(this.focus_date, {months: 1})
    },
    formatMonth(date) {
      return format(date, 'MMMM')
    },
    addEvent() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: false,
          inModal: true,
        },
        component: EventForm,
        fullScreen: false,
        trapFocus: true,
        canCancel: ['outside', 'x'],

      });

    },
    openEventModal(event) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          permissions: {
            can_delete: this.canDelete,
            can_edit: this.canEdit
          },
          inModal: true,
          calendarEvent: event
        },
        component: EventListItem,
        fullScreen: false,
        trapFocus: true,

      })
    },
    setPage(pageNumber, initial = false) {
      this.loading = true;
      this.$store.state.calendar.eventsHold = this.events;
      CalendarEvent.deleteAll();
      this.page = pageNumber;
      CalendarEvent.FetchAll(
          {
            page: 1,
            limit: 999,
          },
          this.filters, ['creator', 'media', 'campuses']
      ).then(
          ({
             response: {
               data: {meta},
             }
           }) => {
            this.meta = meta;
            this.loading = false;
            this.$store.state.calendar.eventsHold = null;
            if (!initial) {
              this.$cookies.set(
                  `calendar_filters`,
                  {
                    page: this.page,

                  },
                  "1d"
              );
            }
          }
      );
    },

  },
  computed: {
    dateGroupedEvents() {
      let dates = []
      this.displayEvents.map(event => {
        let index = dates.findIndex(eventGroup => {
          return eventGroup.date === (format(new Date(event.start_date_time), 'd MMMM'))
        })
        if (index !== -1) {
          dates[index].events.push(event)
        } else {
          dates.push({
            date: format(new Date(event.start_date_time), 'd MMMM'),
            events: [event],
          })
        }

      })
      return dates
    },
    currentMonth() {
      return this.formatMonth(this.focus_date)
    },
    ...mapState('calendar', ['focus_date_filter']),
    campuses() {
      return Campus.query().get()
    },
    calendarOptions() {
      return {
        headerToolbar: false,
        forceEventDuration: true,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        showNonCurrentDates: true,
        expandRows: true,
        contentHeight: '100%',
        height: 'auto',
        eventTimeFormat: {hour12: false, hour: '2-digit', minute: '2-digit'},
        selectable: true,
        initialDate: this.$store.state.calendar.focus_date_filter,
        select: this.handleDateClick,
        eventClick: this.handleEventClick,
        events: this.events,
        longPressDelay: 100

      }
    }
    ,
    displayLimit() {
      if (!this.inModal) {
        return 5
      }
      let value = 5
      if (this.$store.state.size.height < 500) {
        value = 5
      } else {
        value = Math.floor(this.$store.state.size.height / 125)
      }
      // switch (this.$store.getters['size/viewType']) {
      //   case "mobile":
      //     value = 5;
      //     break;
      //   case "tablet":
      //     value = 5;
      //     break;
      //   case "desktop":
      //     value = 6;
      //     break;
      //   case "widescreen":
      //     value = 9;
      //     break;
      //   case "fullhd":
      //     value = 11;
      //     break;
      //   default:
      //     value = 5;
      //     break;
      // }
      return value
    },

    displayEvents() {
      return CalendarEvent.query().where(event => {
        return isAfter(new Date(event.start_date_time), sub(new Date(this.focus_date), {seconds: 1}))
      }).orderBy('start_date_time').with('event_type').offset((this.displayPage - 1) * this.displayLimit)
          .limit(this.displayLimit).get()
    }, displayEventsTotal() {
      return CalendarEvent.query().where(event => {
        return isAfter(new Date(event.start_date_time), sub(new Date(this.focus_date), {seconds: 1}))
      }).orderBy('start_date_time').count()
    },
    focus_date: {
      set(newVal) {


        this.$store.dispatch('calendar/set_focus_date', newVal)
      },
      get() {
        return this.$store.state.calendar.focus_date_filter;
      },
    },
    eventTypes() {
      return EventType.query().get()
    },
    filters() {
      return {
        ...{
          user_id: this.$store.state.user.id,
        }
        ,
        ...(this.$store.getters['calendar/formattedStartDate']
            ? {
              eventStart: this.$store.getters['calendar/formattedStartDate'],
            }
            : {}),
        ...(this.$store.getters['calendar/formattedEndDate']
            ? {
              eventEnd: this.$store.getters['calendar/formattedEndDate'],
            } : {}),
        ...(this.$store.state.calendar.eventTypeFilter !== 'All'
            ? {
              type: this.$store.state.calendar.eventTypeFilter,
            }
            : {}),
        ...(this.is_internal !== null
            ? {
              internal: this.is_internal,
            }
            : {}), ...(this.$store.state.calendar.campus_filter !== null
            ? {
              campus_id: this.$store.state.calendar.campus_filter,
            }
            : {}),
        ...(this.name !== null
            ? {
              name: this.name,
            }
            : {}),

      }
    },
    events() {
      if (this.$store.state.calendar.eventsHold === null) {
        return CalendarEvent.query()
            .with("creator")
            .with('campuses')
            .with('media')
            .orderBy("start_date_time", "desc")
            .get();
      } else {
        return this.$store.state.calendar.eventsHold;
      }

    },
  },
  mounted() {
    this.loading = true
    Promise.all([Campus.FetchAll({page: 1, limit: 999}), EventType.FetchAll({page: 1, limit: 999})]).then(() => {
      if (this.$cookies.isKey(`calendar_filters`)) {
        let filters = this.$cookies.get(`calendar_filters`);
        this.page = filters.page;
        this.$cookies.remove(`calendar_filters`);
      }
      this.setPage(this.page, true)
    })
    if (this.inModal) {
      document.getElementsByClassName('modal-content')[0].classList.add("is-extra-large");
    }

  }
}
</script>
