<template>
  <section class="tile is-ancestor is-fullheight mx-0">


    <div :class="{'px-0':$store.getters['size/isMobile']}"
         class="tile is-4 is-parent is-vertical announcement-event-column">

      <div class="tile is-child is-fullheight">

        <div class="is-reversed-mobile is-fullheight flex-container" :class="{'has-background-white':!$store.state.calendar.mobileCalendarExpanded}">
          <div
              :class="{'is-expanded':$store.state.calendar.expandedPanels.includes('announcements'),'is-collapsed':(!$store.state.calendar.expandedPanels.includes('announcements')&& $store.state.calendar.expandedPanels.length>0)}"
              class="panel-container announcements-panel">
            <div v-if="hasPermission('view announcements') && $store.state.user.id" class="is-fullwidth is-relative">

              <AnnouncementsPanel
                  :can-create="hasPermission('create announcements')"
                  :can-delete="hasPermission('delete announcements')"
                  :can-edit="hasPermission('edit announcements')"
                  :is-expanded="$store.state.calendar.expandedPanels.includes('announcements')"
                  :is-mobile="$store.getters['size/isMobile']"
                  @toggleExpand="$store.getters['size/isMobile']?$store.dispatch('calendar/toggleMobileExpand',false):$store.dispatch('calendar/toggleExpand','announcements')"></AnnouncementsPanel>

              <b-loading :active.sync="$store.state.announcements.loading" :can-cancel="false"
                         :is-full-page="false"></b-loading>
            </div>

          </div>
          <div
              :class="{'is-expanded':$store.state.calendar.expandedPanels.includes('events'),'is-collapsed':(!$store.state.calendar.expandedPanels.includes('events')&& $store.state.calendar.expandedPanels.length>0)}"
              class="panel-container">
            <div v-if="hasPermission('view events') && $store.state.user.id" class="is-fullwidth">
              <CalendarPanel

                  :can-create="hasPermission('create events')"
                  :can-delete="hasPermission('delete events')"
                  :can-edit="hasPermission('edit events')"
                  :is-mobile="true"
                  :is-mobile-expanded="$store.state.calendar.mobileCalendarExpanded"
                  :show-header="true"
                  class="is-hidden-tablet"
                  @toggleExpand="$store.dispatch('calendar/toggleMobileExpand')"></CalendarPanel>
              <EventsPanel v-if="$store.getters['size/isMobile']?$store.state.calendar.mobileCalendarExpanded:true"
                           :can-create="hasPermission('create events')"
                           :can-delete="hasPermission('delete events')"
                           :can-edit="hasPermission('edit events')"
                           :is-expanded="$store.state.calendar.expandedPanels.includes('events')"
                           @toggleExpand="$store.dispatch('calendar/toggleExpand','events')"></EventsPanel>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="tile is-8 is-parent is-vertical is-hidden-mobile">

      <div v-if="hasPermission('view events')" class="tile is-child">

        <div v-if="$store.state.user.id" class="box">

          <CalendarPanel
              :can-create="hasPermission('create events')"
              :can-delete="hasPermission('delete events')"
              :can-edit="hasPermission('edit events')"
              :show-header="true"
              :is-mobile="false"
              @toggleExpand="expandCalendar"></CalendarPanel>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import AnnouncementsPanel from "@/components/announcements/AnnouncementsPanel";
import CalendarPanel from "@/components/calendar/CalendarPanel";
import EventsPanel from "@/components/calendar/EventsPanel";

export default {
  name: "dashboard.index",
  components: {
    EventsPanel,
    CalendarPanel,
    AnnouncementsPanel,
  },
  data() {
    return {
      fetching: false,
      loaded: false,
      selectedCampus: null,
      selectedEnrolmentData: null,
      selectedApplicationData: null,
    };
  },
  mounted() {

  },
  watch: {},
  computed: {
    ...mapState('campus', ['enrolment_filter_year']),


  },
  methods: {
    expandCalendar() {
      this.$store.state.calendar.modalOpen = true
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          canCreate: this.hasPermission('create events'),
          canDelete: this.hasPermission('delete events'),
          canEdit: this.hasPermission('edit events'),
          showEventsList: true,
        },
        component: CalendarPanel,
        fullScreen: true,
        trapFocus: true,
        canCancel: ['outside', 'button', 'x'],
        onCancel: () => {

          this.$store.state.calendar.modalOpen = false
        },
      });

    },
    goToRoute(route) {
      this.$router.push(route);
    },
    hasPermission(permission) {
      return !!this.$store.getters["entities/user-permissions/find"](permission);
    },
  },
};
</script>

<style scoped>
</style>
